import _ from 'lodash';

import { processingStart, renderStart } from '@/modules/Api/api/index.js';
import { startInstallShots } from '@/modules/InstallShots/api/index.js';
import {
  addAssignee,
  addMaterialPreset,
  addMaterialPresetClass,
  changeMaterialDigitalStatus,
  changeMaterialRenderType,
  startSyncEtl,
} from '@/modules/Materials/api/index.js';
import { publishTasks } from '@/modules/Preview/api/index.js';
import { DMA_STATUS, RESPONSE_STATUSES } from '@/utils/constant.js';
import { BATCH_EDIT_ACTIONS } from '@/utils/filters.js';

const cleanObject = (params) => {
  return _.omitBy(
    params,
    (v) =>
      _.isUndefined(v) ||
      _.isNull(v) ||
      v === '' ||
      (Array.isArray(v) && v?.length === 0),
  );
};

export const getQueryParameters = (parameters) => {
  const obj = cleanObject({ ...parameters });
  const searchParams = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key]) && obj[key]?.length) {
      obj[key].forEach((item) => {
        searchParams.append(key, item);
      });
    } else {
      searchParams.append(key, obj[key]);
    }
  });
  return searchParams;
};

export const isStatusOk = (response) => {
  return [
    RESPONSE_STATUSES,
    RESPONSE_STATUSES.CREATED,
    RESPONSE_STATUSES.OK,
  ].includes(response?.status);
};

export const getRequestByActionType = (action, params) => {
  if (action === BATCH_EDIT_ACTIONS.START_RENDER_QA) {
    return renderStart(params);
  }
  if (action === BATCH_EDIT_ACTIONS.START_RENDER_PROD) {
    return renderStart(params);
  }
  if (action === BATCH_EDIT_ACTIONS.LINK_PRESETS) {
    return addMaterialPreset(params);
  }
  if (action === BATCH_EDIT_ACTIONS.BRAND_DISPUTE) {
    return changeMaterialDigitalStatus({
      ...params,
      new_status: DMA_STATUS.brand_dispute,
    });
  }
  if (action === BATCH_EDIT_ACTIONS.RENDER_EDIT) {
    return changeMaterialRenderType(params);
  }
  if (action === BATCH_EDIT_ACTIONS.RENDER_READY) {
    return changeMaterialDigitalStatus({
      ...params,
      new_status: DMA_STATUS.render_ready,
    });
  }
  if (action === BATCH_EDIT_ACTIONS.DMA_ISSUE) {
    return changeMaterialDigitalStatus({
      ...params,
      new_status: DMA_STATUS.dma_issue,
    });
  }
  if (action === BATCH_EDIT_ACTIONS.SET_QA) {
    return changeMaterialDigitalStatus({
      ...params,
      new_status: DMA_STATUS.qa,
    });
  }
  if (action === BATCH_EDIT_ACTIONS.SET_BAD_SOURCE) {
    return changeMaterialDigitalStatus({
      ...params,
      new_status: DMA_STATUS.bad_source,
    });
  }
  if (action === BATCH_EDIT_ACTIONS.SET_QA_APPROVE) {
    return changeMaterialDigitalStatus({
      ...params,
      new_status: DMA_STATUS.qa_approve,
    });
  }
  if (action === BATCH_EDIT_ACTIONS.SET_QA_DECLINE) {
    return changeMaterialDigitalStatus({
      ...params,
      new_status: DMA_STATUS.qa_decline,
    });
  }
  if (action === BATCH_EDIT_ACTIONS.PUBLISH_BRANDS) {
    return changeMaterialDigitalStatus({
      ...params,
      new_status: DMA_STATUS.finished,
    });
  }
  if (action === BATCH_EDIT_ACTIONS.START_DMA_PROCESSING) {
    return processingStart(getQueryParameters(params));
  }
  if (action === BATCH_EDIT_ACTIONS.ASSIGN_PRESET_CLASS) {
    return addMaterialPresetClass(params);
  }
  if (action === BATCH_EDIT_ACTIONS.ETL_UPDATE) {
    return startSyncEtl(params);
  }
  if (action === BATCH_EDIT_ACTIONS.DISTRIBUTE_MATERIALS) {
    return addAssignee(params);
  }
  if (action === BATCH_EDIT_ACTIONS.INSTALL_SHOT_CREATION) {
    return startInstallShots(params);
  }
  if (action === BATCH_EDIT_ACTIONS.PUBLISH_UN_PUBLISH_RENDER_LAYERS) {
    return publishTasks(params);
  }
};
