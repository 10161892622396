import { DMA_STATUS } from '@/utils/constant.js';
import { PERMISSION_ENUM } from '@/utils/permissions/index.js';

export const BATCH_EDIT_ACTIONS = {
  ASSIGN_PRESET_CLASS: 'Assign Preset Class',
  START_DMA_PROCESSING: 'Start DMA Processing',
  RENDER_READY: 'Render Ready',
  START_RENDER_QA: 'Start Render QA',
  START_RENDER_PROD: 'Start Render Prod',
  PUBLISH_BRANDS: 'Publish for Brands',
  SET_QA: 'Set to QA',
  SET_BAD_SOURCE: 'Set to Bad Source',
  LINK_PRESETS: 'Link Presets',
  RENDER_EDIT: 'Render Type Edit',
  DMA_ISSUE: 'DMA Issue',
  BRAND_DISPUTE: 'Brand QA Dispute',
  SET_QA_APPROVE: 'QA Approve',
  SET_QA_DECLINE: 'QA Decline',
  ETL_UPDATE: 'ETL Update',
  DISTRIBUTE_MATERIALS: 'Distribute Materials',
  INSTALL_SHOT_CREATION: 'Install Shot Creation',
  PUBLISH_UN_PUBLISH_RENDER_LAYERS: 'Publish/Unpublish Render Layer',
};
export const BATCH_EDIT_ACTION_OPTIONS = [
  {
    label: 'Distribute Materials',
    value: BATCH_EDIT_ACTIONS.DISTRIBUTE_MATERIALS,
    accessToken: PERMISSION_ENUM.UPDATE_MATERIALS_ASSIGNEE_DISTRIBUTE,
  },
  {
    label: 'ETL Update',
    value: BATCH_EDIT_ACTIONS.ETL_UPDATE,
    accessToken: PERMISSION_ENUM.START_SYNC_ETL,
  },
  {
    label: 'Set to Bad Source',
    value: BATCH_EDIT_ACTIONS.SET_BAD_SOURCE,
    accessToken: PERMISSION_ENUM.CHANGE_DIGITAL_STATUS,
  },
  {
    label: 'Assign Preset Class',
    value: BATCH_EDIT_ACTIONS.ASSIGN_PRESET_CLASS,
    accessToken: PERMISSION_ENUM.UPDATE_MATERIALS_PRESET_CLASS,
  },
  {
    label: 'Start Render Prod',
    value: BATCH_EDIT_ACTIONS.START_RENDER_PROD,
    accessToken: PERMISSION_ENUM.START_RENDERING,
  },
  {
    label: 'Render Type Edit',
    value: BATCH_EDIT_ACTIONS.RENDER_EDIT,
    accessToken: PERMISSION_ENUM.UPDATE_MATERIALS_RENDER_TYPE,
  },
  {
    label: 'Brand QA Dispute',
    value: BATCH_EDIT_ACTIONS.BRAND_DISPUTE,
    accessToken: PERMISSION_ENUM.CHANGE_DIGITAL_STATUS,
  },
  {
    label: 'DMA Issue',
    value: BATCH_EDIT_ACTIONS.DMA_ISSUE,
    accessToken: PERMISSION_ENUM.CHANGE_DIGITAL_STATUS,
  },
  {
    label: 'QA Decline',
    value: BATCH_EDIT_ACTIONS.SET_QA_DECLINE,
    accessToken: PERMISSION_ENUM.CHANGE_DIGITAL_STATUS,
  },
  {
    label: 'Publish for Brands',
    value: BATCH_EDIT_ACTIONS.PUBLISH_BRANDS,
    accessToken: PERMISSION_ENUM.CHANGE_DIGITAL_STATUS,
  },
  {
    label: 'Publish/Unpublish Render Layer',
    value: BATCH_EDIT_ACTIONS.PUBLISH_UN_PUBLISH_RENDER_LAYERS,
    accessToken: PERMISSION_ENUM.UPDATE_PUBLISH_TASKS_TASKS_PUBLISHED_PATCH,
  },
  {
    label: 'Set to QA',
    value: BATCH_EDIT_ACTIONS.SET_QA,
    accessToken: PERMISSION_ENUM.CHANGE_DIGITAL_STATUS,
  },
  {
    label: 'Start Render QA',
    value: BATCH_EDIT_ACTIONS.START_RENDER_QA,
    accessToken: PERMISSION_ENUM.START_RENDERING,
  },
  {
    label: 'Render Ready',
    value: BATCH_EDIT_ACTIONS.RENDER_READY,
    accessToken: PERMISSION_ENUM.CHANGE_DIGITAL_STATUS,
  },
  {
    label: 'Start DMA Processing',
    value: BATCH_EDIT_ACTIONS.START_DMA_PROCESSING,
    accessToken: PERMISSION_ENUM.START_PROCESSING,
  },
  {
    label: 'Link Presets',
    value: BATCH_EDIT_ACTIONS.LINK_PRESETS,
    accessToken: PERMISSION_ENUM.UPDATE_MATERIALS_PRESET,
  },
  {
    label: 'Install Shot Creation',
    value: BATCH_EDIT_ACTIONS.INSTALL_SHOT_CREATION,
    accessToken: PERMISSION_ENUM.START_INSTALL_SHOTS,
  },
];
export const PREDEFINED_FILTERS = {
  [BATCH_EDIT_ACTIONS.SET_BAD_SOURCE]: [
    DMA_STATUS.preset_creation,
    DMA_STATUS.preset_assigned,
    DMA_STATUS.dma_processing,
    DMA_STATUS.dma_error,
    DMA_STATUS.dma_issue,
    DMA_STATUS.dma_processed,
    DMA_STATUS.render_ready,
    DMA_STATUS.rendering,
    DMA_STATUS.render_complete,
    DMA_STATUS.render_error,
    DMA_STATUS.qa,
    DMA_STATUS.qa_decline,
    DMA_STATUS.bad_source,
  ],
  [BATCH_EDIT_ACTIONS.START_DMA_PROCESSING]: [
    DMA_STATUS.preset_assigned,
    DMA_STATUS.dma_error,
    DMA_STATUS.qa_decline,
    DMA_STATUS.dma_issue,
  ],
  [BATCH_EDIT_ACTIONS.RENDER_READY]: [
    DMA_STATUS.dma_processed,
    DMA_STATUS.qa_decline,
  ],
  [BATCH_EDIT_ACTIONS.START_RENDER_QA]: [
    DMA_STATUS.render_ready,
    DMA_STATUS.render_error,
    DMA_STATUS.render_complete,
    DMA_STATUS.finished,
  ],
  [BATCH_EDIT_ACTIONS.START_RENDER_PROD]: [DMA_STATUS.finished],
  [BATCH_EDIT_ACTIONS.PUBLISH_BRANDS]: [
    DMA_STATUS.qa,
    DMA_STATUS.brand_dispute,
    DMA_STATUS.qa_decline,
  ],
  [BATCH_EDIT_ACTIONS.BRAND_DISPUTE]: [DMA_STATUS.finished],
  [BATCH_EDIT_ACTIONS.SET_QA]: [
    DMA_STATUS.render_complete,
    DMA_STATUS.brand_dispute,
  ],
  [BATCH_EDIT_ACTIONS.SET_QA_APPROVE]: [DMA_STATUS.qa],
  [BATCH_EDIT_ACTIONS.SET_QA_DECLINE]: [DMA_STATUS.qa],
  [BATCH_EDIT_ACTIONS.LINK_PRESETS]: [
    DMA_STATUS.preset_assigned,
    DMA_STATUS.preset_creation,
    DMA_STATUS.qa_decline,
    DMA_STATUS.dma_issue,
  ],
  [BATCH_EDIT_ACTIONS.RENDER_EDIT]: [
    DMA_STATUS.preset_assigned,
    DMA_STATUS.preset_creation,
    DMA_STATUS.dma_error,
    DMA_STATUS.dma_processed,
    DMA_STATUS.render_error,
    DMA_STATUS.qa_decline,
    DMA_STATUS.dma_issue,
    DMA_STATUS.render_ready,
    DMA_STATUS.finished,
  ],
  [BATCH_EDIT_ACTIONS.DMA_ISSUE]: [DMA_STATUS.dma_processed],
  [BATCH_EDIT_ACTIONS.ASSIGN_PRESET_CLASS]: [
    DMA_STATUS.preset_creation,
    DMA_STATUS.preset_assigned,
    DMA_STATUS.dma_processing,
    DMA_STATUS.dma_error,
    DMA_STATUS.dma_issue,
    DMA_STATUS.dma_processed,
    DMA_STATUS.render_ready,
    DMA_STATUS.rendering,
    DMA_STATUS.render_complete,
    DMA_STATUS.render_error,
    DMA_STATUS.qa,
    DMA_STATUS.qa_decline,
    DMA_STATUS.brand_dispute,
    DMA_STATUS.finished,
    DMA_STATUS.bad_source,
    DMA_STATUS.etl_sync,
    DMA_STATUS.etl_sync_error,
  ],
  [BATCH_EDIT_ACTIONS.ETL_UPDATE]: [
    DMA_STATUS.preset_creation,
    DMA_STATUS.preset_assigned,
    DMA_STATUS.dma_processing,
    DMA_STATUS.dma_error,
    DMA_STATUS.dma_issue,
    DMA_STATUS.dma_processed,
    DMA_STATUS.render_ready,
    DMA_STATUS.rendering,
    DMA_STATUS.render_complete,
    DMA_STATUS.render_error,
    DMA_STATUS.qa,
    DMA_STATUS.qa_decline,
    DMA_STATUS.brand_dispute,
    DMA_STATUS.finished,
    DMA_STATUS.bad_source,
    DMA_STATUS.etl_sync,
    DMA_STATUS.etl_sync_error,
  ],
  [BATCH_EDIT_ACTIONS.DISTRIBUTE_MATERIALS]: [
    DMA_STATUS.preset_creation,
    DMA_STATUS.preset_assigned,
    DMA_STATUS.dma_processing,
    DMA_STATUS.dma_error,
    DMA_STATUS.dma_issue,
    DMA_STATUS.dma_processed,
    DMA_STATUS.render_ready,
    DMA_STATUS.rendering,
    DMA_STATUS.render_complete,
    DMA_STATUS.render_error,
    DMA_STATUS.qa,
    DMA_STATUS.qa_decline,
    DMA_STATUS.brand_dispute,
    DMA_STATUS.finished,
    DMA_STATUS.bad_source,
    DMA_STATUS.etl_sync,
    DMA_STATUS.etl_sync_error,
  ],
  [BATCH_EDIT_ACTIONS.INSTALL_SHOT_CREATION]: [DMA_STATUS.finished],
  [BATCH_EDIT_ACTIONS.PUBLISH_UN_PUBLISH_RENDER_LAYERS]: [DMA_STATUS.finished],
};
