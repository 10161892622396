import React from 'react';

import { ROUTE_OPTIONS } from '@/shared/components/design-v3/LeftPanel/AdminTab/constant.js';
import { LOCAL_STORAGE_KEYS } from '@/utils/constant.js';
import LocalStorageService from '@/utils/localStorage.js';

export const MaterialsContext = React.createContext();
const DEFAULT_ROUTE_MENU_ITEMS = ROUTE_OPTIONS.map((o) => o?.label);
export const ORDER_FIELD = {
  FILTER_ACTIONS: 'FilterActions',
  SEARCH_WRAPPER: 'SearchWrapper',
  STATUS: 'Status',
  BRAND: 'Brand',
  FAMILY: 'Family',
  PRESET_CLASS: 'Preset Class',
  USERS: 'Users',
  SPACE_APPLICATION: 'Space Application',
  LAYOUTS: 'Layouts',
};

export const DEFAULT_FILTERS_ORDER = [
  ORDER_FIELD.FILTER_ACTIONS,
  ORDER_FIELD.SEARCH_WRAPPER,
  ORDER_FIELD.STATUS,
  ORDER_FIELD.BRAND,
  ORDER_FIELD.FAMILY,
  ORDER_FIELD.PRESET_CLASS,
  ORDER_FIELD.USERS,
  ORDER_FIELD.SPACE_APPLICATION,
  ORDER_FIELD.LAYOUTS,
];
export const MaterialsProvider = ({ children }) => {
  const [action, setAction] = React.useState('');
  const [isQa, setIsQa] = React.useState(false);
  const [isEditLayout, setIsEditLayout] = React.useState(false);
  const [modal, setModal] = React.useState(null);
  const [filterOrder, setFilterOrder] = React.useState(() => {
    const savedFilterOrder = LocalStorageService.getItem(
      LOCAL_STORAGE_KEYS.FILTER_ORDER,
    );

    if (
      DEFAULT_FILTERS_ORDER &&
      savedFilterOrder &&
      Object.keys(DEFAULT_FILTERS_ORDER)?.length !==
        Object.keys(savedFilterOrder || {})?.length // To check if there are new filters that are not present in localStorage.
    ) {
      return DEFAULT_FILTERS_ORDER;
    }

    return savedFilterOrder ? savedFilterOrder : DEFAULT_FILTERS_ORDER;
  });
  const [adminMenuOrder, setAdminMenuOrder] = React.useState(() => {
    const savedFilterOrder = LocalStorageService.getItem(
      LOCAL_STORAGE_KEYS.ADMIN_MENU_ACTIONS,
    );

    return savedFilterOrder ? savedFilterOrder : DEFAULT_ROUTE_MENU_ITEMS;
  });

  return (
    <MaterialsContext.Provider
      value={{
        action,
        setAction,
        modal,
        setModal,
        isEditLayout,
        setIsEditLayout,
        filterOrder,
        setFilterOrder,
        adminMenuOrder,
        setAdminMenuOrder,
        isQa,
        setIsQa,
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};
