import Checked from '@/assets/svg/design-v3/Checked.svg?react';
import Unchecked from '@/assets/svg/design-v3/Unchecked.svg?react';
import IndeterminateIcon from '@/assets/svg/IndeterminateIcon.svg?react';
import { MUI_THEME } from '@/modules/Theme/constant.js';

export const CHECKBOX_VARIANTS = {
  LIGHT: 'LIGHT',
  DEFAULT_DARK: 'DEFAULT_DARK',
};

export const MuiCheckbox = {
  defaultProps: {
    checkedIcon: <Checked />,
    icon: <Unchecked />,
    indeterminateIcon: <IndeterminateIcon />,
  },
  styleOverrides: {
    root: ({ theme, ...props }) => {
      const idDark = theme.palette.mode === MUI_THEME['dark-mode'];
      const variant = props.ownerState.variant;

      return {
        '&.Mui-disabled svg': {
          opacity: 0.6,
          ...(variant === CHECKBOX_VARIANTS.DEFAULT_DARK && {
            opacity: 1,
          }),
          ...(variant === CHECKBOX_VARIANTS.LIGHT && {
            opacity: 1,
          }),
        },
        /*v3-light*/
        '&.MuiCheckbox-root svg rect': {
          ...(variant === CHECKBOX_VARIANTS.LIGHT &&
            !props.ownerState.checked && {
              fill: '#D7D7D9 !important',
            }),
        },
        '&.MuiCheckbox-root:hover svg rect': {
          ...(variant === CHECKBOX_VARIANTS.LIGHT &&
            !props.ownerState.checked && {
              fill: '#EBEBED !important',
            }),
        },
        '&.Mui-disabled svg rect': {
          fill: '#EBEBED !important',
          ...(variant === CHECKBOX_VARIANTS.DEFAULT_DARK && {
            opacity: 1,
            fill: '#262626 !important',
          }),
        },
        /*v3-light*/
        ...(idDark && {
          '&.Mui-checked svg path:nth-of-type(1)': {
            fill: '#36373F',
          },
          '&.Mui-checked svg path:nth-of-type(2)': {
            fill: '#A4A4A4',
          },
          '& svg rect:nth-of-type(2)': {
            // fill: '#27272C',
            stroke: '#4D4E58',
          },
          '&:hover svg rect:nth-of-type(2)': {
            fill: '#222225',
          },
          '&.Mui-disabled svg rect:nth-of-type(2)': {
            fill: '#222225',
            stroke: '#222225',
          },
          '&.Mui-disabled svg': {
            opacity: 0.6,
          },
          '&.Mui-disabled svg path:nth-of-type(1)': {
            fill: '#222225',
          },
        }),
      };
    },
  },
};
