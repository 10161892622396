import React from 'react';
import { createTheme } from '@mui/material';

import { MUI_THEME, THEME, THEME_KEY } from '@/modules/Theme/constant.js';
import { components } from '@/theme/components/components.js';
import { DarkTheme, LightTheme } from '@/utils/materialTheme/index.js';
export const ThemeContext = React.createContext();
const initialTheme = () => {
  // temporary theme disabling
  return THEME.LIGHT;
  // const theme = localStorage.getItem(THEME_KEY);
  // if (theme === null) {
  //   localStorage.setItem(THEME_KEY, THEME.LIGHT);
  //   return THEME.LIGHT;
  // }
  // return theme;
};

export const AppThemeProvider = ({ children }) => {
  // eslint-disable-next-line
  const [theme, setTheme] = React.useState(initialTheme);
  const themeValue = theme === THEME.DARK ? DarkTheme : LightTheme;

  const muiTheme = React.useMemo(() => {
    return createTheme({
      ...themeValue,
      components,
      palette: {
        ...themeValue.palette,
        mode: MUI_THEME[theme],
      },
    });
  }, [theme]);

  const toggleTheme = () => {};
  // temporary theme disabling
  // setTheme((theme) => (theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT));

  React.useLayoutEffect(() => {
    localStorage.setItem(THEME_KEY, theme);
    if (theme === THEME.DARK) {
      document.documentElement.classList.remove(THEME.LIGHT);
      document.documentElement.classList.add(THEME.DARK);
    } else {
      document.documentElement.classList.remove(THEME.DARK);
      document.documentElement.classList.add(THEME.LIGHT);
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, muiTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
